import { Aboyeur } from "@ifood/aboyeur";
import { getMerchantIdFromUrl } from "@whitelabel-webapp/shared/url-utils";

const addressRevision = 0;

export const addressAboyeur = new Aboyeur("address", {
  catch: {
    /**
     * @when Unexpected errors happened
     */
    onError: (error: Error) => ({
      label: "error",
      metadata: { error, merchant_id: getMerchantIdFromUrl() },
    }),
  },
  details: {
    /**
     * @when The user open address details
     */
    open: () => ({
      label: "open",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user closes address details
     */
    close: () => ({
      label: "close",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user accepted to use current location
     */
    useMyLocation: () => ({
      label: "use_my_location",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user enter on search input
     */
    searchForAddressEnter: () => ({
      label: "search_for_address_enter",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user selected searched an address successfully
     */
    searchForAddress: () => ({
      label: "search_for_address",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when Any error happened while searching for address
     */
    searchForAddressError: (error: string) => ({
      label: "search_for_address_error",
      metadata: { error, merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when A New address was added successfully
     */
    addAddress: () => ({
      label: "add_address",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The address is out of the restaurant delivery range
     */
    outOfRange: () => ({
      label: "out_of_range",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The address is out of the restaurant delivery range and user clicks in ok button
     */
    outOfRangeOkClick: () => ({
      label: "out_of_range_ok_i_got_it",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The delivery is unavailable and user clicks in ok button
     */
    deliveryUnavailableOkClick: () => ({
      label: "delivery_unavailable_ok_i_got_it",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user select the takeout option
     */
    takeout: () => ({
      label: "takeout",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The EF service returns unavailable delivery method
     */
    deliveryUnavailable: () => ({
      label: "delivery_unavailable",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user clicks to call the merchant
     */
    call: (merchant_id: string) => ({
      label: "call",
      metadata: { merchant_id },
    }),
  },
}).withMetadata({
  revision: addressRevision,
});;
