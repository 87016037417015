import { Item as CatalogItem } from '@whitelabel-webapp/catalog/shared/models';
import { Order as CheckoutOrder } from '@whitelabel-webapp/checkout/shared/models';

import { FacebookPixel } from './facebook-pixel';
import { GoogleAds } from './google-ads';
import { GoogleAnalytics } from './google-analytics';

const googleAnalytics = new GoogleAnalytics();
const facebookPixel = new FacebookPixel();
const googleAds = new GoogleAds();

export const ecommerceEvents = {
  click(item: CatalogItem) {
    googleAnalytics.click(item);
  },
  shippingInfo() {
    googleAnalytics.shippingInfo();
  },
  search(term: string) {
    googleAnalytics.search(term);
    facebookPixel.search(term);
  },
  viewDetail(item: CatalogItem) {
    googleAnalytics.viewDetail(item);
    facebookPixel.viewDetail(item);
  },
  addToCart(item: CatalogItem) {
    googleAnalytics.addToCart(item);
    facebookPixel.addToCart(item);
  },
  removeFromCart(item: CatalogItem) {
    googleAnalytics.removeFromCart(item);
  },
  beginCheckout(order: CheckoutOrder) {
    googleAnalytics.beginCheckout(order);
    facebookPixel.beginCheckout(order);
  },
  addCoupon(order: CheckoutOrder) {
    googleAnalytics.addCoupon(order);
  },
  checkoutStep(step: number, option: string) {
    googleAnalytics.checkoutStep(step, option);
    facebookPixel.checkoutStep(step, option);
  },
  doCheckout(order: CheckoutOrder, id: string) {
    googleAnalytics.doCheckout(order, id);
  },
};
