import { Customer } from "@whitelabel-webapp/authentication/shared/models";
import {
  WHITELABEL_ACCOUNT_PREFIX,
  WHITELABEL_COOKIES_PREFIX,
  getCustomerLocalStorage,
  isAllRefreshTokensMissing,
  removeAllCustomerAccounts,
  setCustomerLocalStorage,
} from "@whitelabel-webapp/authentication/shared/utils";
import { useState } from "react";

export type SetCustomerType = (customer: Customer) => void;
export type RemoveCustomerType = () => void;

type usePersistedCustomerType = {
  customer: Customer | undefined;
  setCustomer: SetCustomerType;
  removeCustomer: RemoveCustomerType;
};

export function usePersistedCustomer(): usePersistedCustomerType {
  const localCustomer = getCustomerLocalStorage(WHITELABEL_ACCOUNT_PREFIX);

  const [persistedCustomer, setPersistedCustomer] = useState<
    Customer | undefined
  >(() => {
    if (!localCustomer) {
      return;
    }

    if (isAllRefreshTokensMissing([WHITELABEL_COOKIES_PREFIX])) {
      removeAllCustomerAccounts([WHITELABEL_ACCOUNT_PREFIX]);
      return;
    }

    return Customer.fromJSON(localCustomer);
  });

  function handleSetPersistedCustomer(customer?: Customer) {
    if (!customer) {
      removeAllCustomerAccounts([WHITELABEL_ACCOUNT_PREFIX]);
      setPersistedCustomer(undefined);
      return;
    }

    const customerJSON = customer.toJSON();
    setPersistedCustomer(customer);
    setCustomerLocalStorage(WHITELABEL_ACCOUNT_PREFIX, customerJSON);
  }

  function handleRemovePersistedCustomer() {
    setPersistedCustomer(undefined);
  }

  return {
    customer: persistedCustomer,
    setCustomer: handleSetPersistedCustomer,
    removeCustomer: handleRemovePersistedCustomer,
  };
}
