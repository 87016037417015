import { Aboyeur } from "@ifood/aboyeur";
import {
  getAccessPointFromUrl,
  getMerchantIdFromUrl,
  getOriginAreaFromUrl,
} from "@whitelabel-webapp/shared/url-utils";

export const checkoutRevision = 1;

export const checkoutAboyeur = new Aboyeur("checkout", {
  catch: {
    /**
     * @when Fails to get payment methods
     */
    onError: (error: Error) => ({
      label: "error",
      metadata: { error, merchant_id: getMerchantIdFromUrl() },
    }),
  },
  payment: {
    /**
     * @when The user does not add payment method
     */
    viewEmptyPaymentErrorAlert: () => ({
      label: "view_empty_payment_error_alert",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user change the payment method
     */
    selectMethod: (name: string, type: string) => ({
      label: "method_selection",
      metadata: {
        value: name,
        payment_type: type,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),

    /**
     * @when When the user selects whether or not to change
     */
    haveMoneyChange: (value: boolean) => ({
      label: "money_change",
      metadata: { value, merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user validate card token
     */
    validatedCardToken: () => ({
      label: "validated_card_token",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user clicks to change or add a payment method
     */
    click: () => ({
      label: "click",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The merchant does not have payment methods available.
     */
    emptyMethods: (merchant_id: string) => ({
      label: "empty_methods",
      metadata: { merchant_id },
    }),

    /**
     * @when The payment methods request has failed.
     */
    errorPaymentMethodsView: (merchant_id: string) => ({
      label: "error_payment_methods_view",
      metadata: { merchant_id },
    }),

    /**
     * @when The user clicked on try again on payment methods fail dialog.
     */
    errorPaymentMethodsClickTryAgain: (merchant_id: string) => ({
      label: "error_payment_methods_click_try_again",
      metadata: { merchant_id },
    }),

    onError: (error: Error) => ({
      label: "payment_error",
      metadata: { error, merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user clicks in disabled finish order button
     */
    disabledCheckoutOrderButton: () => ({
      label: "disabled_checkout_order_button",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user view delete dialog
     */
    deleteCardView: () => ({
      label: "delete_card_view",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user click in delete button
     */
    deleteCardClick: () => ({
      label: "delete_card_click",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user click in cancel button
     */
    deleteCardCancel: () => ({
      label: "delete_card_cancel",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user click in register new card button
     */
    cardRegistrationButton: () => ({
      label: "card_registration_button",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user click in register new card link
     */
    cardRegistrationLink: () => ({
      label: "card_registration_link",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user open the payment methods modal
     */
    openPayment: () => ({
      label: "open_payment",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user clicks to choose the payment method modal
     */
    choosePayment: () => ({
      label: "choose_payment",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user clicks to close the payment method modal
     */
    closePayment: () => ({
      label: "close_payment",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user clicks to choose delivery payment
     */
    chooseDeliveryPayment: () => ({
      label: "choose_delivery_payment",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user clicks to choose to pay with pix
     */
    choosePixPayment: () => ({
      label: "choose_pix_payment",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user clicks to choose to pay with catalog site
     */
    chooseCatalogPayment: () => ({
      label: "choose_online_payment_card",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }), //
    /**
     * @when The user clicks to save card
     */
    attemptCardRegistration: () => ({
      label: "attempt_card_registration",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user registry a card with success
     */
    successfullyCardRegistration: () => ({
      label: "success_card_registration",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when Failed to registry a card
     */
    failedCardRegistration: (error: any) => ({
      label: "failed_card_registration",
      metadata: {
        error: JSON.stringify(error),
        merchant_id: getMerchantIdFromUrl(),
      },
    }),
  },

  document: {
    /**
     * @when The user clicks to change or add a document
     */
    click: () => ({
      label: "click",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user does not add a mandatory document
     */
    viewEmptyDocumentErrorAlert: () => ({
      label: "view_empty_document_error_alert",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  bag: {
    /**
     * @when The user toggles bag visibility
     */
    click: () => ({
      label: "click",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  deliveryAdditionalInfo: {
    /**
     * @when The user add a delivery additional info message
     */
    add: () => ({
      label: "delivery_additional_info",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  deliveryMethod: {
    /**
     * @when The user sees all delivery methods
     */
    viewDeliveryMethods: () => ({
      label: "view_delivery_methods",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user select a delivery method
     */
    selectDeliveryMethod: (name: string) => ({
      label: "delivery_method",
      metadata: { value: name, merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when The user select a delivery method
     */
    clickSelectedDelivery: (date: string, shift: string) => ({
      label: "click_selected_delivery",
      metadata: {
        date_selected_delivery: date,
        shift_selected_delivery: shift,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),
  },
  details: {
    /**
     * @when The user open checkout details
     */
    open: () => ({
      label: "open",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user close checkout details
     */
    close: () => ({
      label: "close",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user add a new item to the ongoing order
     */
    addItem: (name: string, id: string) => ({
      label: "add_item",
      metadata: {
        item_name: name,
        item_id: id,
        merchant_id: getMerchantIdFromUrl(),
        origin_area: getOriginAreaFromUrl(),
        access_point: getAccessPointFromUrl(),
      },
    }),

    /**
     * @when The user tries to add a new item but the store is unavailable(closed)
     */
    storeUnavailable: () => ({
      label: "store_unavailable",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user tries to add a new item but there are no address
     */
    noAddress: () => ({
      label: "no_address",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user click to change address
     */
    changeAddress: () => ({
      label: "change_address",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user tries to add a new item but there are no delivery method selected
     */
    noDeliveryMethod: () => ({
      label: "no_delivery_method",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user click an item on checkout details to edit
     */
    editItem: (name: string, id: string) => ({
      label: "edit_item",
      metadata: {
        item_name: name,
        item_id: id,
        merchant_id: getMerchantIdFromUrl(),
        origin_area: getOriginAreaFromUrl(),
        access_point: getAccessPointFromUrl(),
      },
    }),

    /**
     * @when The user removed an item from the ongoing order
     */
    removeItem: (name: string, id: string) => ({
      label: "remove_item",
      metadata: {
        item_name: name,
        item_id: id,
        merchant_id: getMerchantIdFromUrl(),
        origin_area: getOriginAreaFromUrl(),
        access_point: getAccessPointFromUrl(),
      },
    }),

    /**
     * @when The user clicks to choose more items
     */
    chooseMoreItems: () => ({
      label: "choose_more_items",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user tried to toggle bag visibility without the minimum order value
     */
    minimumOrderValue: () => ({
      label: "minimum_order_value",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user clicks the checkout button
     */
    checkoutClick: () => ({
      label: "checkout_click",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The checkout has created an Order successfully
     */
    checkoutSuccess: (orderShortId: string, paymentType: string) => ({
      label: "checkout_success",
      metadata: {
        value: orderShortId,
        payment_type: paymentType,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),

    /**
     * @when The checkout has created an Order successfully
     */
    purchase: (value: number, currency: string) => ({
      label: "purchase",
      metadata: {
        value: String(value),
        currency,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),

    /**
     * @when Any error while creating an Order
     */
    checkoutError: (
      errorMessage: string,
      name: string,
      paymentType: string,
    ) => ({
      label: "checkout_error",
      metadata: {
        name,
        error: errorMessage,
        payment_type: paymentType,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),

    /**
     * @when The user clear checkout
     */
    clear: () => ({
      label: "clear",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user can't connect with network
     */
    noNetwork: () => ({
      label: "no_network",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user scrolled to payment section
     */
    scrolledToPaymentSection: () => ({
      label: "scrolled_to_payment_section",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  confirmOrder: {
    /**
     * @when The user open the confirm order modal
     */
    open: () => ({
      label: "open",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user closes the confirm order modal
     */
    close: () => ({
      label: "close",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user clicks the confirm order button
     */
    confirm: () => ({
      label: "confirm",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user clicks the change information button
     */
    changeInfo: () => ({
      label: "change_info",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  divergentDeliveryFee: {
    /**
     * @when The user open the divergent delivery fee modal
     */
    open: () => ({
      label: "open",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user closes the divergent delivery fee modal
     */
    close: () => ({
      label: "close",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user accepts the new delivery fee value and proceed to checkout
     */
    confirm: () => ({
      label: "confirm",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user clicks to cancel the purchase based on the divergent delivery fee
     */
    cancel: () => ({
      label: "cancel",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  anotaAiIntegration: {
    /**
     * @when Fails to integrate with anota ai
     */
    onError: (error: Error, orderId: string) => ({
      label: "anota_ai_integration_error",
      metadata: {
        error,
        order_id: orderId,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),
    /**
     * @when Send order from anota ai
     */
    sendOrder: (sessionId: string, orderId) => ({
      label: "anota_ai_order",
      metadata: {
        value: sessionId,
        order_id: orderId,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),
  },
  sinchIntegration: {
    /**
     * @when Fails to integrate with sinch
     */
    onError: (error: Error, orderId: string) => ({
      label: "sinch_integration_error",
      metadata: {
        error,
        order_id: orderId,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),
    /**
     * @when Send order from sinch
     */
    sendOrder: (sessionId: string, orderId) => ({
      label: "sinch_order",
      metadata: {
        value: sessionId,
        order_id: orderId,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),
  },
}).withMetadata({ revision: checkoutRevision });
