import { CustomerAddress, CustomerAddressJSON } from '@whitelabel-webapp/address/shared/models';
import { MerchantResponse } from '@whitelabel-webapp/merchant/shared/models';
import { useEffect, useMemo, useState } from 'react';
import createPersistedState from 'use-persisted-state';

const CURRENT_ADDRESS_KEY = 'current_address';

export function usePersistedAddressWithMerchant(
  merchant?: MerchantResponse
): [CustomerAddress | undefined, (customerAddress: CustomerAddress) => void] {
  const useLocalChainCurrentAddress = useMemo(
    () =>
      createPersistedState<CustomerAddressJSON>(
        `${merchant?.merchantWhitelabelConfigs.chain.slug}.${CURRENT_ADDRESS_KEY}`,
      ),
    [merchant],
  );

  const clearLocalChainCurrentAddress = () => {
    localStorage.removeItem(
      `${merchant?.merchantWhitelabelConfigs.chain.slug}.${CURRENT_ADDRESS_KEY}`,
    );
  };

  const [localChainCurrentAddress] = useLocalChainCurrentAddress();

  const useLocalMerchantCurrentAddress = useMemo(
    () => createPersistedState<CustomerAddressJSON>(`${merchant?.uuid}.${CURRENT_ADDRESS_KEY}`),
    [merchant]
  );

  const [localMerchantCurrentAddress, setLocalMerchantCurrentAddress] = useLocalMerchantCurrentAddress();

  const [persistedMerchantAddress, setPersistedMerchantAddress] = useState<CustomerAddress | undefined>(() => {
    if (!localMerchantCurrentAddress) return;

    return CustomerAddress.fromJSON(localMerchantCurrentAddress);
  });

  useEffect(() => {
    if (!localChainCurrentAddress) {
      return;
    }

    const chainAddress = CustomerAddress.fromJSON(localChainCurrentAddress);
    handleSetPersistedMerchantAddress(chainAddress);

    clearLocalChainCurrentAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localChainCurrentAddress]);

  function handleSetPersistedMerchantAddress(customerAddress: CustomerAddress) {
    const customerAddressJSON = customerAddress.toJSON();
    setLocalMerchantCurrentAddress(customerAddressJSON);
    setPersistedMerchantAddress(customerAddress);
  }

  return [persistedMerchantAddress, handleSetPersistedMerchantAddress];
}
