import { usePersistedCustomer } from "@whitelabel-webapp/authentication/shared/hooks";
import { Customer } from "@whitelabel-webapp/authentication/shared/models";
import { AuthenticationOrigin } from "@whitelabel-webapp/authentication/shared/types";
import {
  WHITELABEL_ACCOUNT_PREFIX,
  WHITELABEL_COOKIES_PREFIX,
  removeAllAuthCookies,
  removeAllCustomerAccounts,
} from "@whitelabel-webapp/authentication/shared/utils";
import { setFingerprintScripts } from "@whitelabel-webapp/checkout/shared/fingerprint-utils";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { updateAboyeurMetadata } from "@whitelabel-webapp/shared/config";
import { createContext, useContext, useEffect, useState } from "react";

export type AuthenticationMethod = "EMAIL" | "CONTEXT_ID";
export type OpenIfoodAuthenticationOptions = {
  callback?: (customer?: Customer | undefined) => void;
  additionalInfo?: Record<string, string>;
};

export type IfoodAuthenticationConfig = {
  isOpen: boolean;
  method: AuthenticationMethod;
  origin: AuthenticationOrigin;
  options?: OpenIfoodAuthenticationOptions;
};

export type AuthenticationFeatureToggles = {
  isWhatsappOTPToggleEnabled?: boolean;
  isFacebookAuthEnabled?: boolean;
  isGoogleAuthEnabled?: boolean;
};

type AuthenticationContext = {
  customer?: Customer;
  setCustomer: (customer: Customer) => void;
  ifoodAuthenticationConfig: IfoodAuthenticationConfig;
  openIfoodAuthentication: (
    method: AuthenticationMethod,
    origin: AuthenticationOrigin,
    options?: OpenIfoodAuthenticationOptions,
  ) => void;
  featureToggles: AuthenticationFeatureToggles;
  closeIfoodAuthentication: () => void;
  logout: () => void;
};

export const AuthenticationContext = createContext<
  AuthenticationContext | undefined
>(undefined);

export type AuthenticationProviderProps = {
  mockCustomer?: Customer;
  mockAuthenticationConfig?: IfoodAuthenticationConfig;
  featureToggles: AuthenticationFeatureToggles;
};

const INITIAL_AUTHENTICATION_CONFIG: IfoodAuthenticationConfig = {
  isOpen: false,
  method: "EMAIL",
  origin: "checkout",
};

export const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({
  mockCustomer,
  mockAuthenticationConfig,
  featureToggles,
  children,
}) => {
  const { merchant } = useMerchant();
  const [ifoodAuthenticationConfig, setIfoodAuthenticationConfig] =
    useState<IfoodAuthenticationConfig>(
      mockAuthenticationConfig ?? INITIAL_AUTHENTICATION_CONFIG,
    );

  const { customer, setCustomer, removeCustomer } = usePersistedCustomer();

  useEffect(() => {
    if (!customer || !customer.user.id) return;

    if (customer.user.tenantId !== merchant.getTenantId()) {
      handleLogout();
      return;
    }

    updateAboyeurMetadata(customer.user.id);

    if (!customer.user.email) return;

    setFingerprintScripts(customer.user.email);
  }, [customer, merchant]);

  function handleOpenIfoodAuthentication(
    method: AuthenticationMethod,
    origin: AuthenticationOrigin,
    options?: OpenIfoodAuthenticationOptions,
  ) {
    setIfoodAuthenticationConfig({
      isOpen: true,
      method,
      origin,
      options,
    });
  }

  function handleCloseIfoodAuthentication() {
    setIfoodAuthenticationConfig(INITIAL_AUTHENTICATION_CONFIG);
  }

  async function handleLogout() {
    try {
      await Customer.revokeAuthentication();
      removeAllCustomerData();
    } catch (error) {
      removeAllCustomerData();
    }
  }

  function removeAllCustomerData() {
    removeAllAuthCookies([WHITELABEL_COOKIES_PREFIX]);
    removeAllCustomerAccounts([WHITELABEL_ACCOUNT_PREFIX]);
    removeCustomer();
  }

  const value: AuthenticationContext = {
    customer: mockCustomer ?? customer,
    setCustomer,
    ifoodAuthenticationConfig,
    openIfoodAuthentication: handleOpenIfoodAuthentication,
    featureToggles,
    closeIfoodAuthentication: handleCloseIfoodAuthentication,
    logout: handleLogout,
  };

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

AuthenticationProvider.displayName = "AuthenticationProvider";

export function useAuthentication(): AuthenticationContext {
  const context = useContext(AuthenticationContext);

  if (typeof context === "undefined") {
    throw new Error(
      `'useAuthentication()' must be used within a ${AuthenticationProvider.displayName}`,
    );
  }

  return context;
}
