export type CoordinateResponse = {
  latitude: number;
  longitude: number;
};

export class Coordinate {
  static fromApi(rawCoordinate: CoordinateResponse) {
    return new Coordinate(rawCoordinate.latitude, rawCoordinate.longitude);
  }

  constructor(public latitude: number, public longitude: number) {}
}
