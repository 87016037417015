import { Aboyeur } from "@ifood/aboyeur";
import { Step as StepConst } from "@whitelabel-webapp/authentication/shared/constants";
import { OTPType } from "@whitelabel-webapp/authentication/shared/models";
import {
  AuthenticationOrigin,
  iFoodAccountSteps,
} from "@whitelabel-webapp/authentication/shared/types";
import { getMerchantIdFromUrl } from "@whitelabel-webapp/shared/url-utils";

type Step = keyof typeof StepConst;

type AuthType = "PHONE" | "EMAIL" | "FACEBOOK" | "GOOGLE";

const authenticationRevision = 1;

export const authenticationAboyeur = new Aboyeur("authentication", {
  catch: {
    /**
     * @when An unexpected error occurs during an authentication
     */
    onError: (error: Error) => ({
      label: "error",
      metadata: { error, merchant_id: getMerchantIdFromUrl() },
    }),
  },
  authentication: {
    /**
     * @when A refresh token was successfully set
     */
    refreshAccessToken: () => ({
      label: "refresh_access_token",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The token retry process starts
     */
    retry: () => ({
      label: "retry",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when An unexpected error occurs during an authentication with google
     */
    googleError: (error: Error) => ({
      label: "google_authentication_error",
      metadata: { error, merchant_id: getMerchantIdFromUrl() },
    }),
    /**
     * @when An unexpected error occurs during an authentication with facebook
     */
    facebookError: (error: Error) => ({
      label: "facebook_authentication_error",
      metadata: { error, merchant_id: getMerchantIdFromUrl() },
    }),
  },
  customer: {
    /**
     * @when A user is logged in
     */
    loggedIn: (type: AuthType) => ({
      label: "logged_in",
      metadata: {
        value: type,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),
  },
  otp: {
    /**
     * @when An OTP step is visualized
     */
    stepView: (name: Step) => ({
      label: "step_view",
      metadata: {
        step_name: StepConst[name],
        merchant_id: getMerchantIdFromUrl(),
      },
    }),

    /**
     * @when An OTP step continue button is clicked
     */
    stepContinueClick: (name: Step) => ({
      label: "step_continue_click",
      metadata: {
        step_name: StepConst[name],
        merchant_id: getMerchantIdFromUrl(),
      },
    }),

    /**
     * @when The Access Token was successfully generated
     */
    generateAccessToken: () => ({
      label: "generate_access_token",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The Authorization code was successfully sent
     */
    sendAuthorizationCode: (type: OTPType) => ({
      label: "send_authorization_code",
      metadata: {
        value: type,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),
  },
  account: {
    /**
     * @when open idle modal
     */
    openIdle: (metadata: {
      value: AuthenticationOrigin;
      merchant_id: string;
    }) => ({
      label: "open_idle",
      metadata,
    }),
    /**
     * @when open signin modal
     */
    openSignIn: () => ({
      label: "open_sign_in",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when open signin modal
     */
    openSignUp: () => ({
      label: "open_sign_up",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when get user data from Facebook API successfully
     */
    getFacebookUserData: () => ({
      label: "get_facebook_user_data",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when get user data from Google API successfully
     */
    getGoogleUserData: () => ({
      label: "get_google_user_data",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when click in Facebook button
     */
    clickFacebookAuthentication: () => ({
      label: "click_facebook_authentication",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when click in Google button
     */
    clickGoogleAuthentication: () => ({
      label: "click_google_authentication",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when click in e-mail sign in button
     */
    clickEmailAuthentication: () => ({
      label: "click_email_authentication",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when click in phone sign in button
     */
    clickPhoneAuthentication: () => ({
      label: "click_phone_authentication",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when open dont received code modal
     */
    dontReceivedCode: (type: OTPType) => ({
      label: "open_dont_received_code",
      metadata: {
        value: type,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),

    /**
     * @when open expired code modal
     */
    expiredCode: (type: OTPType) => ({
      label: "open_expired_code",
      metadata: {
        value: type,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),

    /**
     * @when a new code is resent
     */
    resendCode: (type: OTPType) => ({
      label: "resend_code",
      metadata: {
        value: type,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),

    /**
     * @when e-mail or phone is changed
     */
    changeOTPValue: (type: OTPType) => ({
      label: "change_otp_value",
      metadata: {
        value: type,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),

    /**
     * @when An Ifood Account step is visualized
     */
    stepView: (stepName: iFoodAccountSteps) => ({
      label: "step_view",
      metadata: {
        step_name: stepName,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),

    /**
     * @when An Ifood Account step continue button is clicked
     */
    stepContinueClick: (stepName: iFoodAccountSteps) => ({
      label: "step_continue_click",
      metadata: {
        step_name: stepName,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),
    /**
     * @when a user is created
     */
    userCreated: (type: AuthType) => ({
      label: "create_user",
      metadata: {
        value: type,
        merchant_id: getMerchantIdFromUrl(),
      },
    }),
  },
}).withMetadata({
  revision: authenticationRevision,
});
