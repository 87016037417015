import { checkoutAboyeur } from "@whitelabel-webapp/checkout/shared/config";
import {
  DeliveryMethod,
  DeliveryMethodJSON,
  MerchantResponse,
} from "@whitelabel-webapp/merchant/shared/models";
import { useMemo, useState } from "react";
import createPersistedState from "use-persisted-state";

const CURRENT_DELIVERY_METHOD_KEY = "current_deliveryMethod";

export function usePersistedDeliveryMethod(
  merchant?: MerchantResponse,
): [DeliveryMethod | undefined, (deliveryMethod: DeliveryMethod) => void] {
  const useLocalCurrentDeliveryMethod = useMemo(
    () =>
      createPersistedState<DeliveryMethodJSON>(
        `${merchant?.uuid}.${CURRENT_DELIVERY_METHOD_KEY}`,
      ),
    [merchant],
  );

  const [localCurrentDeliveryMethod, setLocalCurrentDeliveryMethod] =
    useLocalCurrentDeliveryMethod();
  const [persistedDeliveryMethod, setPersistedDeliveryMethod] = useState<
    DeliveryMethod | undefined
  >(() => {
    if (!localCurrentDeliveryMethod) {
      return undefined;
    }

    return DeliveryMethod.fromJSON(localCurrentDeliveryMethod);
  });

  function handleSetPersistedDeliveryMethod(deliveryMethod: DeliveryMethod) {
    const deliveryMethodJSON = deliveryMethod.toJSON();
    setLocalCurrentDeliveryMethod(deliveryMethodJSON);
    setPersistedDeliveryMethod(deliveryMethod);

    const isTheSameDeliveryMethod =
      JSON.stringify(deliveryMethod) == JSON.stringify(persistedDeliveryMethod);

    if (!isTheSameDeliveryMethod) {
      checkoutAboyeur.events.deliveryMethod.selectDeliveryMethod(
        deliveryMethod.mode,
      );
    }
  }

  return [persistedDeliveryMethod, handleSetPersistedDeliveryMethod];
}
