import { Order as CheckoutOrder } from '@whitelabel-webapp/checkout/shared/models';

import { GoogleAnalytics } from './google-analytics';

export class GoogleAds extends GoogleAnalytics {
  doConversion(order: CheckoutOrder, transactionId: string, googleAdsID: string) {
    this.gtagPush('conversion', {
      send_to: googleAdsID,
      transaction_id: transactionId,
      currency: order.totalOrder.currency,
      value: order.totalOrder.getValue(),
    });
  }
}
