import { ENVS, getIsDevelopment } from '@whitelabel-webapp/shared/config';

const FINGERPRINT_SESSION_ID_KEY = 'fingerprint_session_id';

export const getFingerprintSessionId = () => sessionStorage.getItem(FINGERPRINT_SESSION_ID_KEY) || '';

export const setFingerprintScripts = (userEmail: string) => {
  if (!userEmail || getIsDevelopment()) return null;

  const persistedSessionId = sessionStorage.getItem(FINGERPRINT_SESSION_ID_KEY);

  const randomValue = Math.ceil(Math.random() * 1000);
  const sessionId = persistedSessionId || btoa(`${userEmail}${randomValue}`);

  addClearsale(sessionId);
  addCybersource(sessionId);

  if (!persistedSessionId) {
    sessionStorage.setItem(FINGERPRINT_SESSION_ID_KEY, sessionId);
  }

  return sessionId;
};

const addClearsale = (sessionId: string) => {
  if (!Boolean(ENVS.CLEARSALE_APP_ID)) return;
  document.body.appendChild(
    (() => {
      const scriptTag = document.createElement("script");
      scriptTag.innerHTML = `(function (a, b, c, d, e, f, g) {
      a['CsdpObject'] = e; a[e] = a[e] || function () {
      (a[e].q = a[e].q || []).push(arguments)
      }, a[e].l = 1 * new Date(); f = b.createElement(c),
      g = b.getElementsByTagName(c)[0]; f.async = 1; f.src = d; g.parentNode.insertBefore(f, g)
      })(window, document, 'script', '//device.clearsale.com.br/p/fp.js', 'csdp');
      csdp('app', '${ENVS.CLEARSALE_APP_ID}');
      csdp('sessionid', '${sessionId}');`;
      scriptTag.type = "text/javascript";
      return scriptTag;
    })(),
  );
};

const addCybersource = (sessionId: string) => {
  if (!Boolean(ENVS.CYBERSOURCE_APP_ID)) return;
  document.body.appendChild(
    (() => {
      const scriptTag = document.createElement("script");
      scriptTag.src = `https://h.online-metrix.net/fp/tags.js?org_id=${ENVS.CYBERSOURCE_APP_ID}&session_id=${sessionId}`;
      scriptTag.type = "text/javascript";
      return scriptTag;
    })(),
  );

  document.body.appendChild(
    (() => {
      const iframeTag = document.createElement("iframe");
      iframeTag.src = `https://h.online-metrix.net/fp/tags?org_id=${ENVS.CYBERSOURCE_APP_ID}&session_id=${sessionId}`;
      iframeTag.height = "100px";
      iframeTag.width = "100px";
      iframeTag.style.position = "absolute";
      iframeTag.style.top = "-5000px";
      return iframeTag;
    })(),
  );

  return sessionId;
};
