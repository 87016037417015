import { addMinutes, format, getDay, isBefore, parse } from "date-fns";

const WEEKDAYS = {
  MONDAY: "MONDAY",
  TUESDAY: "TUESDAY",
  WEDNESDAY: "WEDNESDAY",
  THURSDAY: "THURSDAY",
  FRIDAY: "FRIDAY",
  SATURDAY: "SATURDAY",
  SUNDAY: "SUNDAY",
} as const;

export type Weekdays = keyof typeof WEEKDAYS;

export type MerchantShiftResponse = {
  dayOfWeek: Weekdays;
  start: string;
  duration: number;
};

export type MerchantShift = {
  start: string;
  duration: number;
};

export type WeekdayShifts = {
  [key in Weekdays]: MerchantShift[];
};

type Day = 0 | 1 | 2 | 3 | 4 | 5 | 6;

type WeekDay =
  | "MONDAY"
  | "TUESDAY"
  | "WEDNESDAY"
  | "THURSDAY"
  | "FRIDAY"
  | "SATURDAY"
  | "SUNDAY";

const WEEKDAY_LABELS = {
  MONDAY: "Segunda",
  TUESDAY: "Terça",
  WEDNESDAY: "Quarta",
  THURSDAY: "Quinta",
  FRIDAY: "Sexta",
  SATURDAY: "Sábado",
  SUNDAY: "Domingo",
};

const WEEKDAY_LABELS_WITH_PREFIX = {
  MONDAY: "na segunda",
  TUESDAY: "na terça",
  WEDNESDAY: "na quarta",
  THURSDAY: "na quinta",
  FRIDAY: "na sexta",
  SATURDAY: "no sábado",
  SUNDAY: "no domingo",
};

const WEEKDAY_LABELS_WITH_NEXT_PREFIX = {
  MONDAY: "na próxima segunda",
  TUESDAY: "na próxima terça",
  WEDNESDAY: "na próxima quarta",
  THURSDAY: "na próxima quinta",
  FRIDAY: "na próxima sexta",
  SATURDAY: "no próximo sábado",
  SUNDAY: "no próximo domingo",
};

const DATE_FNS_DAY_OF_WEEK = {
  0: WEEKDAYS.SUNDAY,
  1: WEEKDAYS.MONDAY,
  2: WEEKDAYS.TUESDAY,
  3: WEEKDAYS.WEDNESDAY,
  4: WEEKDAYS.THURSDAY,
  5: WEEKDAYS.FRIDAY,
  6: WEEKDAYS.SATURDAY,
};

const weekdaySorter = {
  [WEEKDAYS.MONDAY]: 1,
  [WEEKDAYS.TUESDAY]: 2,
  [WEEKDAYS.WEDNESDAY]: 3,
  [WEEKDAYS.THURSDAY]: 4,
  [WEEKDAYS.FRIDAY]: 5,
  [WEEKDAYS.SATURDAY]: 6,
  [WEEKDAYS.SUNDAY]: 7,
};

const weekdayLabelSorter = {
  [WEEKDAY_LABELS.MONDAY]: 1,
  [WEEKDAY_LABELS.TUESDAY]: 2,
  [WEEKDAY_LABELS.WEDNESDAY]: 3,
  [WEEKDAY_LABELS.THURSDAY]: 4,
  [WEEKDAY_LABELS.FRIDAY]: 5,
  [WEEKDAY_LABELS.SATURDAY]: 6,
  [WEEKDAY_LABELS.SUNDAY]: 7,
};

const CLOSED = "FECHADO";

const initialWeekDayShifts: WeekdayShifts = {
  [WEEKDAYS.MONDAY]: [],
  [WEEKDAYS.TUESDAY]: [],
  [WEEKDAYS.WEDNESDAY]: [],
  [WEEKDAYS.THURSDAY]: [],
  [WEEKDAYS.FRIDAY]: [],
  [WEEKDAYS.SATURDAY]: [],
  [WEEKDAYS.SUNDAY]: [],
};

export class MerchantShifts {
  constructor(public list: WeekdayShifts) {}

  static fromApi(shiftResponse: MerchantShiftResponse[]) {
    const weekdayShifts = shiftResponse.reduce<WeekdayShifts>((acc, cur) => {
      return {
        ...acc,
        [cur.dayOfWeek]: [
          ...acc[cur.dayOfWeek],
          { start: cur.start, duration: cur.duration },
        ],
      };
    }, initialWeekDayShifts);

    return new MerchantShifts(weekdayShifts);
  }

  static isConsecutiveWeekdays(a: Weekdays, b: Weekdays) {
    const aOrder = weekdaySorter[a];
    const bOrder = weekdaySorter[b];

    return bOrder - aOrder === 1;
  }

  static getConsecutiveWeekdays(weekdays: Weekdays[]) {
    if (weekdays.length === 1) {
      return [weekdays];
    }

    let weekdayTupleRange = [weekdays[0]];
    const consecutiveWeekdays = [];
    for (let index = 0; index < weekdays.length; ) {
      const currentDay = weekdays[index];
      const nextIndex = index + 1;
      const nextDay = weekdays[nextIndex];

      const isConsecutive = MerchantShifts.isConsecutiveWeekdays(
        currentDay,
        nextDay,
      );
      if (isConsecutive) {
        weekdayTupleRange[1] = nextDay;
        index++;
        continue;
      }

      consecutiveWeekdays.push(weekdayTupleRange);
      index = nextIndex;
      weekdayTupleRange = [nextDay];
    }

    return consecutiveWeekdays;
  }

  static generateWeekDayDescription(
    weekday: Weekdays,
    shifts: MerchantShift[],
  ) {
    const formatTime = (time: Date) => format(time, "HH:mm");
    const toString = ({ start, close }: { start: Date; close: Date }) =>
      `${formatTime(start)} às ${formatTime(close)}`;
    const stringStart = `${WEEKDAY_LABELS[weekday]}: `;

    if (shifts.length === 0) {
      return `${stringStart} ${CLOSED}`;
    }

    const formattedShifts = shifts
      .map((shift) => {
        const start = parse(shift.start, "HH:mm:ss", new Date());
        const close = addMinutes(start, shift.duration);

        return { start, close };
      })
      .sort((shift1, shift2) => (isBefore(shift2.start, shift1.start) ? 1 : -1))
      .map(toString)
      .join(" - ");

    return formattedShifts;
  }

  getFormattedShiftStrings() {
    const shiftsByWeekday = Object.keys(this.list).map((weekday) => ({
      weekday,
      shifts: this.getWeekdayShift(weekday as Weekdays),
    }));

    const weekdaysByShift = shiftsByWeekday.reduce(
      (acc: Record<string, string[]>, { weekday, shifts }) => {
        const currentShifts = acc[shifts] || [];

        return {
          ...acc,
          [shifts]: currentShifts.concat(weekday),
        };
      },
      {},
    );

    const formattedShifts = Object.entries(weekdaysByShift).reduce(
      (acc: Record<string, string>, [shifts, weekdays]) => {
        const consecutiveWeekdays = MerchantShifts.getConsecutiveWeekdays(
          weekdays as Weekdays[],
        );

        consecutiveWeekdays
          .map((weekdays) => weekdays.map((weekday) => WEEKDAY_LABELS[weekday]))
          .forEach((consecutiveWeekday: string[]) => {
            const formattedConsecutiveWeekday = consecutiveWeekday.join(" a ");

            acc[formattedConsecutiveWeekday] = shifts;
          });

        return acc;
      },
      {},
    );

    return Object.entries(formattedShifts)
      .sort((a, b) => {
        const [aDayLabel] = a[0].split(" a ");
        const [bDayLabel] = b[0].split(" a ");
        const aOrder = weekdayLabelSorter[aDayLabel];
        const bOrder = weekdayLabelSorter[bDayLabel];

        return aOrder - bOrder;
      })
      .map(([days, shifts]) => `${days}: ${shifts}`);
  }

  getWeekdayShift(weekday: Weekdays) {
    return MerchantShifts.generateWeekDayDescription(
      weekday,
      this.list[weekday],
    );
  }

  isEmpty() {
    return Object.keys(this.list).every(
      (weekday) => this.list[weekday as Weekdays].length === 0,
    );
  }

  getNextMerchantAvailableLabel(mockToday?: Date) {
    const currentDateTime = mockToday ?? new Date();
    const day = getDay(currentDateTime) as Day;
    const currentWeekDay = DATE_FNS_DAY_OF_WEEK[day] as WeekDay;
    const isMerchantScheduledToday = this.list[currentWeekDay].length > 0;

    if (isMerchantScheduledToday) {
      const nextOpenTime = this.list[currentWeekDay].filter((shift) => {
        return isBefore(
          currentDateTime,
          parse(shift.start, "HH:mm:ss", currentDateTime),
        );
      });

      if (nextOpenTime.length > 0) {
        const sortedNextOpenTime = nextOpenTime.sort((a, b) =>
          isBefore(
            parse(b.start, "HH:mm:ss", currentDateTime),
            parse(a.start, "HH:mm:ss", currentDateTime),
          )
            ? 1
            : -1,
        );

        return `Abriremos às ${format(
          parse(sortedNextOpenTime[0].start, "HH:mm:ss", currentDateTime),
          "HH:mm",
        )}`;
      }
    }

    let nextScheduledDay = "";
    const initialIndex =
      getDay(currentDateTime) + 1 > 6 ? 0 : getDay(currentDateTime) + 1;

    for (let index = initialIndex; index !== getDay(currentDateTime); ) {
      if (index > 6) {
        index = 0;
      }
      if (index === getDay(currentDateTime)) {
        break;
      }

      const currentIndexWeekDay =
        DATE_FNS_DAY_OF_WEEK[index as keyof typeof DATE_FNS_DAY_OF_WEEK];
      const hasScheduleToCurrentDay = this.list[currentIndexWeekDay].length > 0;

      if (hasScheduleToCurrentDay) {
        nextScheduledDay = currentIndexWeekDay;
        break;
      }

      index++;
    }

    if (nextScheduledDay) {
      return `Abriremos ${
        WEEKDAY_LABELS_WITH_PREFIX[nextScheduledDay as Weekdays]
      }`;
    }

    return `Abriremos ${WEEKDAY_LABELS_WITH_NEXT_PREFIX[currentWeekDay]}`;
  }
}
