import { createAuthenticatedAxiosClient } from "@whitelabel-webapp/authentication/shared/utils";
import { Merchant } from "@whitelabel-webapp/merchant/shared/models";
import { groceriesApiBffURL } from "@whitelabel-webapp/shared/config";
import { isStringEqual } from "@whitelabel-webapp/shared/string-utils";
import { AxiosInstance } from "axios";

import { Coordinate, CoordinateResponse } from "./coordinate";

export type CustomerAddressPayload = {
  country: string;
  state: string;
  city: string;
  neighborhood: string;
  streetName: string;
  coordinates: Coordinate;
  postalCode: string;
  reference: string;
  provider: string;
  streetNumber?: string;
  complement?: string;
};

export type CustomerAddressJSON = Omit<
  CustomerAddressResponse,
  "id" | "externalId"
>;

export type CustomerAddressResponse = {
  id: string;
  externalId: number;
  country: string;
  state: string;
  city: string;
  neighborhood: string;
  streetName: string;
  coordinates: CoordinateResponse;
  postalCode: string;
  reference: string;
  streetNumber?: string;
  complement?: string;
};

export type CheckoutLocation = {
  zipCode: number;
  lat: number;
  lon: number;
  address: string;
  district: string;
  country: string;
  state: string;
  city: string;
};

export type PayloadCustomerAddress = {
  uuid: string;
  addressId: number;
  streetNumber: number;
  location: CheckoutLocation;
  reference?: string;
  complement?: string;
};

export class CustomerAddress {
  static client: AxiosInstance;

  static initClient(merchant: Merchant): void {
    CustomerAddress.client = createAuthenticatedAxiosClient(
      groceriesApiBffURL,
      merchant.id,
    );
  }

  static async getCustomerAddresses(
    merchant: Merchant,
  ): Promise<CustomerAddressResponse[]> {
    if (!CustomerAddress.client) {
      CustomerAddress.initClient(merchant);
    }

    const { data } = await CustomerAddress.client.get<
      CustomerAddressResponse[]
    >(`/v1/customers/me/addresses`);

    return data;
  }

  static async createCustomerAddress(
    payload: CustomerAddressPayload,
    merchant: Merchant,
  ): Promise<CustomerAddressResponse> {
    if (!CustomerAddress.client) {
      CustomerAddress.initClient(merchant);
    }

    const { data } = await CustomerAddress.client.post<CustomerAddressResponse>(
      `/logistics/v1/customers/me/addresses`,
      payload,
    );

    return data;
  }

  static fromApi(rawCustomerAddress: CustomerAddressResponse) {
    return new CustomerAddress(
      rawCustomerAddress.country,
      rawCustomerAddress.state,
      rawCustomerAddress.city,
      rawCustomerAddress.neighborhood,
      rawCustomerAddress.streetName,
      Coordinate.fromApi(rawCustomerAddress.coordinates),
      rawCustomerAddress.postalCode,
      rawCustomerAddress.reference,
      rawCustomerAddress.streetNumber,
      rawCustomerAddress.complement,
      rawCustomerAddress.id,
      rawCustomerAddress.externalId,
    );
  }

  static fromJSON(rawCustomerAddress: CustomerAddressJSON) {
    return new CustomerAddress(
      rawCustomerAddress.country,
      rawCustomerAddress.state,
      rawCustomerAddress.city,
      rawCustomerAddress.neighborhood,
      rawCustomerAddress.streetName,
      Coordinate.fromApi(rawCustomerAddress.coordinates),
      rawCustomerAddress.postalCode,
      rawCustomerAddress.reference,
      rawCustomerAddress.streetNumber,
      rawCustomerAddress.complement,
    );
  }

  static fromMerchant(merchant: Merchant) {
    return new CustomerAddress(
      merchant.address.country,
      merchant.address.state,
      merchant.address.city,
      merchant.address.district,
      merchant.address.streetName,
      new Coordinate(merchant.address.latitude, merchant.address.longitude),
      merchant.address.zipCode,
      "",
      merchant.address.streetNumber,
    );
  }

  static fromMerchantToPayload(merchant: Merchant): PayloadCustomerAddress {
    return {
      uuid: "",
      addressId: 0,
      reference: "",
      streetNumber: parseInt(merchant.address.streetNumber.replace(/\D+/g, "")),
      location: {
        zipCode: parseInt(merchant.address.zipCode.replace(/\D+/g, "")),
        lat: merchant.address.latitude,
        lon: merchant.address.longitude,
        address: merchant.address.streetName,
        district: merchant.address.district,
        country: merchant.address.country,
        state: merchant.address.state,
        city: merchant.address.city,
      },
    };
  }

  constructor(
    public country: string,
    public state: string,
    public city: string,
    public neighborhood: string,
    public streetName: string,
    public coordinates: Coordinate,
    public postalCode: string,
    public reference: string,
    public streetNumber?: string,
    public complement?: string,
    public id?: string,
    public externalId?: number,
  ) {}

  public toPayload(): PayloadCustomerAddress {
    return {
      uuid: this.id ?? "",
      addressId: this.externalId ?? 0,
      reference: this.reference ?? "",
      complement: this.complement ?? "",
      streetNumber: this.streetNumber
        ? parseInt(this.streetNumber.replace(/\D+/g, ""))
        : 0,
      location: {
        zipCode: this.postalCode
          ? parseInt(this.postalCode.replace(/\D+/g, ""))
          : 0,
        lat: this.coordinates.latitude,
        lon: this.coordinates.longitude,
        address: this.streetName,
        district: this.neighborhood,
        country: this.country,
        state: this.state,
        city: this.city,
      },
    };
  }

  public toJSON(): CustomerAddressJSON {
    return {
      country: this.country,
      state: this.state,
      city: this.city,
      neighborhood: this.neighborhood,
      streetName: this.streetName,
      coordinates: {
        latitude: this.coordinates.latitude,
        longitude: this.coordinates.longitude,
      },
      postalCode: this.postalCode,
      reference: this.reference,
      streetNumber: this.streetNumber,
      complement: this.complement,
    };
  }

  withPayload(address: CustomerAddress | CustomerAddressResponse) {
    this.id = address.id;
    this.externalId = address.externalId ?? 0;
    this.reference = address.reference ?? "";
    this.country = address.country;
    this.city = address.city;
    this.state = address.state;

    return this;
  }

  static async getMaximumThreeLastDifferentAddresses(merchant: Merchant) {
    const customerAddresses =
      await CustomerAddress.getCustomerAddresses(merchant);

    const differentAddresses = customerAddresses.reduce<
      CustomerAddressResponse[]
    >((acc, address) => {
      const registeredCustomerAddress = acc.find(
        (customerAddress) =>
          isStringEqual(customerAddress.country, address.country) &&
          isStringEqual(customerAddress.state, address.state) &&
          isStringEqual(customerAddress.city, address.city) &&
          isStringEqual(customerAddress.streetName, address.streetName) &&
          isStringEqual(
            customerAddress.streetNumber ?? "",
            address.streetNumber ?? "",
          ),
      );
      if (!registeredCustomerAddress) {
        return [...acc, address];
      }
      return acc;
    }, []);

    const merchantCoordinates = {
      latitude: merchant.address.latitude,
      longitude: merchant.address.longitude,
    } as Coordinate;

    const differentAddressesLimitedToThree =
      differentAddresses.length > 3
        ? findClosestAddresses(merchantCoordinates, differentAddresses)
        : differentAddresses;

    const addressesDeliveryMethods = await Promise.all(
      differentAddressesLimitedToThree.map((address) => {
        return merchant.getDeliveryMethod(
          address.coordinates.latitude,
          address.coordinates.longitude,
        );
      }),
    );

    const validDifferentAddressesLimitedToThree =
      differentAddressesLimitedToThree.filter((_, index) => {
        return Boolean(addressesDeliveryMethods[index]);
      });

    return validDifferentAddressesLimitedToThree.map(
      (customerAddressResponse) =>
        CustomerAddress.fromApi(customerAddressResponse),
    );
  }

  async updateOrCreate(merchant: Merchant) {
    const customerAddresses =
      await CustomerAddress.getCustomerAddresses(merchant);
    const registeredCustomerAddress = customerAddresses.find(
      (customerAddress) =>
        isStringEqual(customerAddress.country, this.country) &&
        isStringEqual(customerAddress.state, this.state) &&
        isStringEqual(customerAddress.city, this.city) &&
        isStringEqual(customerAddress.neighborhood, this.neighborhood) &&
        isStringEqual(customerAddress.streetName, this.streetName) &&
        isStringEqual(customerAddress.reference, this.reference) &&
        isStringEqual(
          customerAddress.streetNumber ?? "",
          this.streetNumber ?? "",
        ) &&
        isStringEqual(customerAddress.complement ?? "", this.complement ?? ""),
    );

    if (registeredCustomerAddress) {
      return this.withPayload(registeredCustomerAddress);
    }

    const newCustomerAddress = await CustomerAddress.createCustomerAddress(
      {
        city: this.city,
        coordinates: this.coordinates,
        country: this.country,
        neighborhood: this.neighborhood,
        postalCode: this.postalCode,
        state: this.state,
        streetName: this.streetName,
        streetNumber: this.streetNumber,
        complement: this.complement,
        reference: this.reference,
        provider: "GOOGLE",
      },
      merchant,
    );

    return this.withPayload(newCustomerAddress);
  }

  toCheckout(): PayloadCustomerAddress {
    if (!this.id || !this.externalId) {
      throw new Error("Address must be initialized before creating an Order");
    }

    return this.toPayload();
  }
}

function findClosestAddresses(
  originalCoordinates: Coordinate,
  addresses: CustomerAddressResponse[],
) {
  const calculateDistance = (
    coordinate1: Coordinate,
    coordinate2: Coordinate,
  ) => {
    const earthRadius = 6371;
    const toRadians = (angle: number) => (angle * Math.PI) / 180;

    const lat1 = toRadians(coordinate1.latitude);
    const lon1 = toRadians(coordinate1.longitude);
    const lat2 = toRadians(coordinate2.latitude);
    const lon2 = toRadians(coordinate2.longitude);

    const distanceLatitude = lat2 - lat1;
    const distanceLongitude = lon2 - lon1;

    const centralAngle =
      0.5 -
      Math.cos(distanceLatitude) / 2 +
      (Math.cos(lat1) * Math.cos(lat2) * (1 - Math.cos(distanceLongitude))) / 2;

    return earthRadius * 2 * Math.asin(Math.sqrt(centralAngle));
  };

  type ClosestAddress = {
    address: CustomerAddressResponse;
    distance: number;
  };

  let closestAddresses: ClosestAddress[] = [];

  addresses.forEach((address) => {
    const distance = calculateDistance(
      originalCoordinates,
      address.coordinates,
    );

    if (closestAddresses.length < 3) {
      closestAddresses.push({ address, distance });
      closestAddresses.sort(
        (a: ClosestAddress, b: ClosestAddress) => a.distance - b.distance,
      );
    } else if (distance < closestAddresses[2].distance) {
      closestAddresses.pop();
      closestAddresses.push({ address, distance });
      closestAddresses.sort(
        (a: ClosestAddress, b: ClosestAddress) => a.distance - b.distance,
      );
    }
  });

  return closestAddresses.map((closestAddress) => closestAddress.address);
}
