export const Step = {
  name: 'name',
  phone: 'phone',
  otp: 'otp',
} as const;

export const StepOrder = {
  name: 1,
  otp: 2,
  phone: 3,
} as const;
