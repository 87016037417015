export type Phone = {
  countryCode: number;
  areaCode: number;
  number: string;
  fullNumber: string;
};

export type UserResponsePhone = {
  country_code: number;
  area_code: number;
  number: string;
  full_number: string;
};

export type UserResponse = {
  id: string;
  external_id: number;
  name: string;
  tax_payer_identification_number: string | null;
  phone: UserResponsePhone;
  cpf: string;
  email: string;
  language: string;
  registration_date: string;
  tenant_id: string;
  active: boolean;
  test_scope: string | null;
  user_type: string;
};

export type UserJSON = {
  id: string;
  externalId: number;
  name: string;
  taxPayerIdentificationNumber: string | null;
  phone: Phone;
  email: string;
  language: string;
  registrationDate: string;
  tenantId: string;
  active: boolean;
  testScope: string | null;
  userType: string;
  cpf: string;
};

export class User {
  static fromApi(rawUser: UserResponse): User {
    return new User(
      rawUser.id,
      rawUser.external_id,
      rawUser.name,
      rawUser.tax_payer_identification_number,
      User.toUserPhone(rawUser.phone),
      rawUser.email,
      rawUser.language,
      rawUser.registration_date,
      rawUser.tenant_id,
      rawUser.active,
      rawUser.test_scope,
      rawUser.user_type,
      rawUser.cpf
    );
  }

  static fromJSON(rawJSON: UserJSON) {
    return new User(
      rawJSON.id,
      rawJSON.externalId,
      rawJSON.name,
      rawJSON.taxPayerIdentificationNumber,
      rawJSON.phone,
      rawJSON.email,
      rawJSON.language,
      rawJSON.registrationDate,
      rawJSON.tenantId,
      rawJSON.active,
      rawJSON.testScope,
      rawJSON.userType,
      rawJSON.cpf
    );
  }

  static toUserPhone(rawPhone: UserResponsePhone): Phone {
    return {
      areaCode: rawPhone.area_code,
      countryCode: rawPhone.country_code,
      fullNumber: rawPhone.full_number,
      number: rawPhone.number,
    };
  }

  constructor(
    public id: string,
    public externalId: number,
    public name: string,
    public taxPayerIdentificationNumber: string | null,
    public phone: Phone,
    public email: string,
    public language: string,
    public registrationDate: string,
    public tenantId: string,
    public active: boolean,
    public testScope: string | null,
    public userType: string,
    public cpf: string
  ) {}
}
