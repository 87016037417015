import { authenticationAboyeur } from "@whitelabel-webapp/authentication/shared/config";
import {
  ACCESS_TOKEN_KEY,
  APP_VERSION_KEY,
  CUSTOMER_KEY,
  DEVICE_ID_KEY,
  PAYMENT_SECRET_KEY,
  PLATFORM,
  REFRESH_TOKEN_KEY,
  SESSION_ID_KEY,
} from "@whitelabel-webapp/authentication/shared/constants";
import {
  Customer,
  CustomerJSON,
} from "@whitelabel-webapp/authentication/shared/models";
import { merchantStorage } from "@whitelabel-webapp/merchant/shared/storage";
import { isMobileBrowser } from "@whitelabel-webapp/shared/browser-utils";
import {
  getDeviceId,
  getIsDevelopment,
  getSessionId,
} from "@whitelabel-webapp/shared/config";
import { CookiesStorage } from "@whitelabel-webapp/shared/models";
import { getDomainWithoutSubdomain } from "@whitelabel-webapp/shared/url-utils";
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { CookieSetOptions } from "universal-cookie";

import pkg from "../../../../../package.json";

type SetMerchantAccessTokenNamedParams = {
  prefix: string;
  token: string;
  path: string;
};

export const WHITELABEL_COOKIES_PREFIX = "wl";
export const WHITELABEL_ACCOUNT_PREFIX = "wl";

export const buildAccessTokenKey = (prefix: string) =>
  `${prefix}.${ACCESS_TOKEN_KEY}`;
export const buildRefreshTokenKey = (prefix: string) =>
  `${prefix}.${REFRESH_TOKEN_KEY}`;
export const buildCustomerKey = (prefix: string) => `${prefix}.${CUSTOMER_KEY}`;

export const getCookieSetOptions = (
  maybeWhitelabelPrefixOrMerchantUuid: string,
): CookieSetOptions => {
  const isMerchantUuid =
    maybeWhitelabelPrefixOrMerchantUuid !== WHITELABEL_COOKIES_PREFIX;
  const path = isMerchantUuid ? `/${maybeWhitelabelPrefixOrMerchantUuid}` : "/";

  return {
    path,
    httpOnly: false,
    secure: getIsDevelopment() ? false : true,
    sameSite: "lax",
    maxAge: 31536000, // 1 year
  } as CookieSetOptions;
};

export const getAccessToken = (prefix: string): string | undefined => {
  const key = buildAccessTokenKey(prefix);
  const token = CookiesStorage.get(key);

  return token ?? undefined;
};

export const setAccessToken = (prefix: string, token: string) => {
  const key = buildAccessTokenKey(prefix);
  const cookieOptions = getCookieSetOptions(prefix);
  CookiesStorage.set(key, token, cookieOptions);
};

export const setAccessTokenWithCustomPath = ({
  prefix,
  token,
  path,
}: SetMerchantAccessTokenNamedParams) => {
  const key = buildAccessTokenKey(prefix);
  const cookieOptions = getCookieSetOptions(path);
  CookiesStorage.set(key, token, cookieOptions);
};

export const removeAccessToken = (prefix: string) => {
  const key = buildAccessTokenKey(prefix);
  CookiesStorage.delete(key);
};

export const getRefreshToken = (prefix: string): string | undefined => {
  const key = buildRefreshTokenKey(prefix);
  const token = CookiesStorage.get(key);

  return token ?? undefined;
};

export const setRefreshToken = (prefix: string, token: string) => {
  const key = buildRefreshTokenKey(prefix);
  const cookieOptions = getCookieSetOptions(prefix);
  CookiesStorage.set(key, token, cookieOptions);
};

type SetRefreshTokenWithCustomPathNamedParams = {
  prefix: string;
  token: string;
  path: string;
};
export const setRefreshTokenWithCustomPath = ({
  prefix,
  token,
  path,
}: SetRefreshTokenWithCustomPathNamedParams) => {
  const key = buildRefreshTokenKey(prefix);
  const cookieOptions = getCookieSetOptions(path);
  CookiesStorage.set(key, token, cookieOptions);
};

export const removeRefreshToken = (prefix: string) => {
  const key = buildRefreshTokenKey(prefix);
  CookiesStorage.delete(key);
};

export const getCustomerLocalStorage = (
  prefix: string,
): CustomerJSON | undefined => {
  if (typeof window === "undefined") return;

  const key = buildCustomerKey(prefix);
  const customer = localStorage.getItem(key);

  if (!customer) {
    return;
  }

  return JSON.parse(customer);
};

export const getCustomerCookie = (prefix: string): CustomerJSON | undefined => {
  const key = buildCustomerKey(prefix);
  const customer = CookiesStorage.get(key);

  if (!customer) {
    return;
  }

  return customer;
};

export const setCustomerLocalStorage = (
  prefix: string,
  value: CustomerJSON,
) => {
  const key = buildCustomerKey(prefix);
  const storageValue = JSON.stringify(value);

  localStorage.setItem(key, storageValue);
};

export const removeCustomerLocalStorage = (prefix: string) => {
  const key = buildCustomerKey(prefix);
  localStorage.removeItem(key);
};

const setAppVersion = () => {
  const key = `${WHITELABEL_COOKIES_PREFIX}.${APP_VERSION_KEY}`;
  const cookieOptions = getCookieSetOptions(WHITELABEL_COOKIES_PREFIX);

  CookiesStorage.set(key, pkg.version, cookieOptions);
};

const setSessionId = () => {
  const key = `${WHITELABEL_COOKIES_PREFIX}.${SESSION_ID_KEY}`;
  const cookieOptions = getCookieSetOptions(WHITELABEL_COOKIES_PREFIX);
  const sessionId = getSessionId();

  CookiesStorage.set(key, sessionId, cookieOptions);
};

const setDeviceId = () => {
  const key = `${WHITELABEL_COOKIES_PREFIX}.${DEVICE_ID_KEY}`;
  const cookieOptions = getCookieSetOptions(WHITELABEL_COOKIES_PREFIX);
  const deviceId = getDeviceId();

  CookiesStorage.set(key, deviceId, cookieOptions);
};

export const buildPaymentSecret = (email: string) =>
  btoa(email + getDeviceId()).replace(/[^A-z\s\d][\\^]?/g, "");

export const setPaymentSecret = (email: string) => {
  const key = `${WHITELABEL_COOKIES_PREFIX}.${PAYMENT_SECRET_KEY}`;
  const cookieOptions = getCookieSetOptions(WHITELABEL_COOKIES_PREFIX);
  const paymentSecret = buildPaymentSecret(email);

  CookiesStorage.set(key, paymentSecret, cookieOptions);
};

export const setAuthenticationCookies = (
  accessToken: string,
  refreshToken: string,
  email?: string,
) => {
  setAccessToken(WHITELABEL_COOKIES_PREFIX, accessToken);
  setRefreshToken(WHITELABEL_COOKIES_PREFIX, refreshToken);
  setAppVersion();
  setDeviceId();
  setSessionId();

  if (email) setPaymentSecret(email);
};

export const getPaymentSecretCookie = () =>
  CookiesStorage.get(`${WHITELABEL_COOKIES_PREFIX}.${PAYMENT_SECRET_KEY}`);

export const hasPaymentSecretCookie = () => Boolean(getPaymentSecretCookie());

const injectAuthorizationHeaderInterceptor =
  (merchantId: string) => (config: AxiosRequestConfig) => {
    let token: string | undefined;

    token = getAccessToken(WHITELABEL_COOKIES_PREFIX);

    if (!token) {
      token = getAccessToken(merchantId);
    }

    if (!token) {
      return config;
    }

    const authorizationHeader = `Bearer ${token}`;

    if (config?.headers) {
      config.headers["Authorization"] = authorizationHeader;
      config.headers["X-Ifood-Device-Id"] = getDeviceId();
      config.headers["X-Ifood-Session-Id"] = getSessionId();
      config.headers["app_version"] = pkg.version;
      config.headers["app_name"] = pkg.name;
      config.headers["platform"] = isMobileBrowser.any()
        ? PLATFORM.MOBILE
        : PLATFORM.DESKTOP;
      return config;
    }

    config.headers = {
      Authorization: authorizationHeader,
    };

    return config;
  };

export const createAuthenticatedAxiosClient = (
  baseURL: string,
  merchantId: string = "",
  headers?: AxiosRequestHeaders,
) => {
  const client = axios.create({ baseURL, headers });

  client.interceptors.request.use(
    injectAuthorizationHeaderInterceptor(merchantId),
  );

  client.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      let cookiesPrefix = "";

      const ifoodRefreshToken = getRefreshToken(WHITELABEL_COOKIES_PREFIX);

      if (ifoodRefreshToken) {
        cookiesPrefix = WHITELABEL_COOKIES_PREFIX;
      }

      const whitelabelRefreshToken = getRefreshToken(merchantId);

      if (!ifoodRefreshToken && whitelabelRefreshToken) {
        cookiesPrefix = merchantId;
      }

      const refreshToken = ifoodRefreshToken ?? whitelabelRefreshToken;

      if (!refreshToken) {
        removeAllCustomerAccounts([WHITELABEL_ACCOUNT_PREFIX, merchantId]);
        removeAllAuthCookies([WHITELABEL_COOKIES_PREFIX, merchantId]);
        return Promise.reject(error);
      }

      if (
        !error.response ||
        error.response.status !== 401 ||
        originalRequest._retry
      ) {
        return Promise.reject(error);
      }

      originalRequest._retry = true;
      removeAccessToken(cookiesPrefix);

      authenticationAboyeur.events.authentication.retry();

      try {
        const merchantQuery = merchantStorage.path;
        const cookiesPath =
          cookiesPrefix === merchantId && merchantQuery
            ? merchantQuery
            : cookiesPrefix;
        const { access_token, refresh_token } =
          await Customer.refreshAccessToken(refreshToken);

        setAccessTokenWithCustomPath({
          prefix: cookiesPrefix,
          token: access_token,
          path: cookiesPath,
        });
        setRefreshTokenWithCustomPath({
          prefix: cookiesPrefix,
          token: refresh_token,
          path: cookiesPath,
        });

        authenticationAboyeur.events.authentication.refreshAccessToken();

        return client(originalRequest);
      } catch (refreshTokenError) {
        removeAllCustomerAccounts([WHITELABEL_ACCOUNT_PREFIX, merchantId]);
        removeAllAuthCookies([WHITELABEL_COOKIES_PREFIX, merchantId]);
        return Promise.reject(refreshTokenError);
      }
    },
  );

  return client;
};

export const removeAllCustomerAccounts = (accountPrefixes: string[]) => {
  accountPrefixes.forEach((prefix) => {
    removeCustomerLocalStorage(prefix);
  });
};

export const removeAllAuthCookies = (cookiesPrefixes: string[]) => {
  cookiesPrefixes.forEach((prefix) => {
    removeAccessToken(prefix);
    removeRefreshToken(prefix);
  });
};

export const isAllRefreshTokensMissing = (cookiesPrefixes: string[]) => {
  return cookiesPrefixes.every((prefix) => !getRefreshToken(prefix));
};
