import { addressAboyeur } from '@whitelabel-webapp/address/shared/config';
import {
  usePersistedAddressWithChain,
  usePersistedAddressWithMerchant,
  usePersistedDeliveryMethod,
} from '@whitelabel-webapp/address/shared/hooks';
import { AutocompleteAddress, CustomerAddress } from '@whitelabel-webapp/address/shared/models';
import { ChainResponse } from '@whitelabel-webapp/chain/shared/models';
import { DeliveryMethod, MerchantResponse } from '@whitelabel-webapp/merchant/shared/models';
import { createContext, useContext, useState } from 'react';

type AddressType = 'MERCHANT' | 'CHAIN';

type AddressSettings = {
  defaultAutocompleteAddress?: AutocompleteAddress;
};

type AddressConfig = {
  isOpen: boolean;
  type: AddressType;
  onClose?: () => void;
  settings?: AddressSettings;
};

export type AddressContextValue = {
  deliveryMethod: DeliveryMethod | undefined;
  setDeliveryMethod: (deliveryMethod: DeliveryMethod) => void;
  getAddress: (type: AddressType) => CustomerAddress | undefined;
  setAddress: (type: AddressType, address: CustomerAddress) => void;
  addressConfig: AddressConfig;
  openAddress: (type: AddressType, settings?: AddressSettings, onClose?: () => void) => void;
  closeAddress: () => void;
};

const AddressContext = createContext<AddressContextValue | undefined>(undefined);

export type AddressProviderProps = {
  initialAddressConfig?: AddressConfig;
  merchantResponse?: MerchantResponse;
  chainResponse?: ChainResponse;
};

const INITIAL_ADDRESS_CONFIG: AddressConfig = {
  isOpen: false,
  type: 'MERCHANT',
};

export const AddressProvider: React.FC<AddressProviderProps> = ({
  initialAddressConfig,
  merchantResponse,
  chainResponse,
  children,
}) => {
  const [persistedDeliveryMethod, setPersistedDeliveryMethod] = usePersistedDeliveryMethod(merchantResponse);

  const [persistedChainAddress, setPersistedChainAddress] = usePersistedAddressWithChain(chainResponse);
  const [persistedMerchantAddress, setPersistedMerchantAddress] = usePersistedAddressWithMerchant(merchantResponse);

  const [addressConfig, setAddressConfig] = useState(initialAddressConfig ?? INITIAL_ADDRESS_CONFIG);

  function handleGetAddress(type: AddressType) {
    if (type === 'CHAIN') return persistedChainAddress;

    return persistedMerchantAddress;
  }

  function handleSetAddress(type: AddressType, address: CustomerAddress) {
    if (type === 'CHAIN') return setPersistedChainAddress(address);

    return setPersistedMerchantAddress(address);
  }

  function handleOpenAddress(type: AddressType, settings?: AddressSettings, onClose?: () => void) {
    setAddressConfig({
      isOpen: true,
      type,
      onClose,
      settings,
    });
    addressAboyeur.events.details.open();
  }

  function handleCloseAddress() {
    setAddressConfig(INITIAL_ADDRESS_CONFIG);
    addressAboyeur.events.details.close();
  }

  const contextValue: AddressContextValue = {
    deliveryMethod: persistedDeliveryMethod,
    setDeliveryMethod: setPersistedDeliveryMethod,
    addressConfig,
    getAddress: handleGetAddress,
    setAddress: handleSetAddress,
    openAddress: handleOpenAddress,
    closeAddress: handleCloseAddress,
  };

  return <AddressContext.Provider value={contextValue}>{children}</AddressContext.Provider>;
};

AddressProvider.displayName = 'AddressProvider';

export function useAddress(): AddressContextValue {
  const context = useContext(AddressContext);

  if (typeof context === 'undefined') {
    throw new Error(`'useAddress()' must be used within a '${AddressProvider.displayName}'`);
  }

  return context;
}
