export type RuleResponse = {
  key: string;
  localizedLabel: string;
  required: boolean;
};

export class Rules {
  static fromApi(rawRules: RuleResponse[]) {
    return new Rules(rawRules);
  }

  constructor(public list: RuleResponse[]) {}

  isReferenceMandatory() {
    return !!this.list.find((rule) => rule.key === 'reference' && rule.required);
  }
}
