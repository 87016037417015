export type AuthenticationResponse = {
  authenticated: boolean;
  account_id: string;
  access_token: string;
  refresh_token: string;
};

export type AuthenticationJSON = {
  authenticated: boolean;
  accountId: string;
  accessToken: string;
  refreshToken: string;
};

export class Authentication {
  static fromApi(rawApi: AuthenticationResponse) {
    return new Authentication(rawApi.authenticated, rawApi.account_id, rawApi.access_token, rawApi.refresh_token);
  }

  static fromJSON(rawJSON: AuthenticationJSON) {
    return new Authentication(rawJSON.authenticated, rawJSON.accountId, rawJSON.accessToken, rawJSON.refreshToken);
  }

  constructor(
    public authenticated: boolean,
    public accountId: string,
    public accessToken: string,
    public refreshToken: string
  ) {}
}
