import { Merchant, MerchantResponse } from '@whitelabel-webapp/merchant/shared/models';
import { merchantStorage } from '@whitelabel-webapp/merchant/shared/storage';
import { FC, createContext, useContext, useEffect, useMemo, useState } from 'react';

type MerchantContext = {
  merchant: Merchant;
  setMerchant: (newMerchant: Merchant) => void;
};

export const MerchantContext = createContext<MerchantContext | undefined>(
  undefined,
);

export type MerchantProviderProps = {
  merchantResponse: MerchantResponse;
};

export const MerchantProvider: FC<MerchantProviderProps> = ({
  children,
  merchantResponse,
}) => {
  const merchant = useMemo(
    () => Merchant.fromApi(merchantResponse),
    [merchantResponse],
  );

  const [value, setValue] = useState(() => {
    return { merchant, setMerchant: handleSetMerchant };
  });

  useEffect(() => {
    merchantStorage.path = merchant.query;
  }, [merchant]);

  function handleSetMerchant(merchant: Merchant) {
    setValue((prevValue) => ({
      ...prevValue,
      merchant,
    }));
  }

  return (
    <MerchantContext.Provider value={value}>
      {children}
    </MerchantContext.Provider>
  );
};

MerchantProvider.displayName = 'MerchantProvider';

export function useMerchant(): MerchantContext {
  const context = useContext(MerchantContext);

  if (typeof context === 'undefined') {
    throw new Error(`'useMerchant()' must be used within a ${MerchantProvider.displayName}`);
  }

  return context;
}
